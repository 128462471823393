/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';
import config from '../config/config';
import routes from '../config/routes';
import AudioManager from '../lib/AudioManager';
import IconPlay from './icons/IconPlay';
import IconRestart from './icons/IconRestart';

const TestAudioPlayback: React.FC = () => {
  React.useEffect(() => ReactGA.pageview(routes.TestAudioPlayback), []);

  const [hasAudioPlayed, setHasAudioPlayed] = React.useState(false);
  const [showTips, setShowTips] = React.useState(false);

  const play = () => {
    setHasAudioPlayed(false);
    setShowTips(false);

    AudioManager.createAudio({
      src: config.audio.audioPlaybackTest,
      onend: () => {
        setHasAudioPlayed(true);
      },
    });
  };

  function onClick() {
    setShowTips(true);
    setHasAudioPlayed(false);
  }

  return (
    <>
      <h1 className="h1 pt-16 mx-auto mb-2 text-center">Audio Output</h1>

      <main className="justify-stretch align-stretch flex flex-col flex-1 p-4 text-center">
        <div className="text-center">
          <section className="py-4 space-y-4">
            {hasAudioPlayed === false && showTips === false && (
              <p className="mx-auto">Please play this audio file to check your sound level and adjust accordingly.</p>
            )}

            {showTips && (
              <div className="well">
                If you can&rsquo;t hear the sample audio when it&rsquo;s playing, you may need to turn up your volume,
                make sure your browser or device isn&rsquo;t muted, or put on your headphones. You can repeat the sample
                audio playback as many times as you need as you do this.
              </div>
            )}

            <div className="well">
              {hasAudioPlayed === false ? (
                <>
                  <h3 className="h3">Sample audio</h3>

                  <button
                    type="button"
                    className="button button--primary block mx-auto mb-4 text-2xl font-semibold"
                    onClick={() => play()}
                  >
                    <span className="inline-block w-6 mr-1 -mt-1 align-middle">
                      <IconPlay />{' '}
                    </span>
                    <small className="inline-block align-middle">Play</small>
                  </button>
                </>
              ) : (
                <>
                  <h3 className="h3">Did you hear that?</h3>
                  <Link
                    to={routes.RelaxationIntro}
                    className="button button--primary mx-auto mb-4 text-2xl font-semibold"
                  >
                    Yes
                  </Link>{' '}
                  <button
                    type="button"
                    onClick={onClick}
                    className="button button--primary mx-auto mb-4 text-2xl font-semibold"
                  >
                    No
                  </button>
                </>
              )}
              <button
                type="button"
                className="button block px-2 py-1 mx-auto text-xs font-semibold"
                onClick={() => play()}
              >
                <span className="inline-block w-4 -mt-1 align-middle">
                  <IconRestart />{' '}
                </span>
                <small className="inline-block font-semibold align-middle">Replay audio</small>
              </button>
            </div>
          </section>
        </div>
        <Link
          to={routes.RelaxationIntro}
          onClick={() => AudioManager.stop()}
          className="button mx-auto mb-4 text-2xl font-semibold"
        >
          Skip
        </Link>
      </main>
      <a className="session-pause-exit__button mt-auto" href={config.api.root}>
        <span className="h2 text-blue text-center">Exit</span>
      </a>
    </>
  );
};

export default TestAudioPlayback;
