import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="self-end w-full max-w-full px-3 py-4 m-0">
      <div className="border-blue-50 py-3 border-t">
        <p className="text-xs text-right">© {new Date().getFullYear()} Vivir Emotional Fitness Institute</p>
      </div>
    </footer>
  );
};

export default Footer;
