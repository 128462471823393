/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import React from 'react';
import ReactGA from 'react-ga';
import { RouteChildrenProps } from 'react-router-dom';
import config from '../config/config';
import routes from '../config/routes';
import { useTherapySession } from '../contexts/TherapySessionContext';
import logout from '../data/user/logout';
import AudioManager from '../lib/AudioManager';
import randomBetween from '../utils/randomBetween';
import ArithmeticQuestion from './ArithmeticQuestion';

const ReorientationQuestion: React.FC<RouteChildrenProps> = ({ history }) => {
  React.useEffect(() => ReactGA.pageview(routes.Reorientation), []);

  const [repeat, setRepeat] = React.useState(0);
  const [correctCount, setCorrectCount] = React.useState(0);
  const [answer, setAnswer] = React.useState(randomBetween(5, 20));
  const { alarms } = useTherapySession();

  const onCorrect = () => {
    setCorrectCount(count => count + 1);
  };

  React.useEffect(() => {
    setAnswer(Math.ceil(Math.random() * 20));
  }, [correctCount]);

  React.useEffect(() => {
    const timeout = window.setTimeout(() => {
      if (alarms?.hasAlarms) {
        AudioManager.createAudio({ src: config.audio.reorientation, onend: () => setRepeat(r => r + 1) });
      } else {
        setRepeat(r => r + 1);
      }
    }, config.reorientation.attempt1Timeout);

    return () => {
      window.clearTimeout(timeout);
    };
  }, [alarms]);

  React.useEffect(() => {
    let timeout: number;

    if (repeat === 1) {
      timeout = window.setTimeout(() => {
        if (alarms?.hasAlarms) {
          AudioManager.createAudio({ src: config.audio.alarm, onend: () => setRepeat(r => r + 1) });
        } else {
          setRepeat(r => r + 1);
        }
      }, config.reorientation.attempt2Timeout);
    } else if (repeat === 2) {
      timeout = window.setTimeout(() => window.location.replace('/'), config.reorientation.attempt3Timeout);
    }

    return () => {
      if (timeout) {
        window.clearTimeout(timeout);
      }
    };
  }, [history, repeat, alarms]);

  return (
    <>
      <header className="pt-8 pb-2 text-center">
        <h1 className="h1 mx-auto mb-2">Reorientation</h1>
      </header>
      <main className="justify-stretch align-stretch flex flex-col flex-1 p-4 text-center">
        {correctCount < 2 ? (
          <ArithmeticQuestion answer={answer} onCorrect={onCorrect} />
        ) : (
          <div className="well md:w-4/5 flex flex-col space-y-4">
            <p className="mx-auto mb-10 font-sans text-lg text-center normal-case">
              Thank you for using the Emotional Updating™ App today. Please take whatever time is required to make sure
              that you are fully alert and oriented to your surroundings before engaging in any other activities. Be
              well and enjoy your day.
            </p>
            <a href={config.api.root} className="button button--primary">
              Home
            </a>
            <button className="button" type="button" onClick={logout}>
              Logout
            </button>
          </div>
        )}
      </main>
    </>
  );
};

export default ReorientationQuestion;
