import React from 'react';
import ReactGA from 'react-ga';
import { RouteChildrenProps } from 'react-router-dom';
import config from '../config/config';
import routes from '../config/routes';
import AudioManager from '../lib/AudioManager';

const TherapySessionIntro: React.FC<RouteChildrenProps> = ({ history }) => {
  React.useEffect(() => ReactGA.pageview(routes.SessionIntro), []);

  AudioManager.createAudio({
    src: config.audio.therapySessionIntro,
    onend: () => {
      history.push(`/session/${config.session.startId}`);
    },
  });

  return (
    <>
      <main className="justify-stretch align-stretch flex flex-col justify-center flex-1 p-4 text-center">
        <div className="sm:max-w-md mx-auto">
          <p className="mb-2">
            Thank you for your &ldquo;Yes&rdquo; and &ldquo;No&rdquo; replies. What now follows is a series of questions
            to be answered with either &ldquo;Yes&rdquo; or &ldquo;No&rdquo;.
          </p>
          <p>There are no wrong answers to any of these questions.</p>
        </div>
      </main>
      <a href={config.api.root} className="session-pause-exit__button">
        <span className="h2 text-blue text-center">Exit</span>
      </a>
    </>
  );
};

export default TherapySessionIntro;
