import { Switch } from '@headlessui/react';
import React from 'react';
import ReactGA from 'react-ga';
import { useHistory } from 'react-router-dom';
import config from '../config/config';
import routes from '../config/routes';
import { useTherapySession } from '../contexts/TherapySessionContext';
import AudioManager from '../lib/AudioManager';
import Footer from './common/Footer';

const Home: React.FC = () => {
  const history = useHistory();
  const { alarms, relaxationOnly, facilitatorSession, therapySession } = useTherapySession();

  React.useEffect(() => ReactGA.pageview('/start'), []);

  React.useEffect(() => {
    console.log(relaxationOnly);

    if (relaxationOnly) {
      history.replace(routes.Meditation);
    }

    if (facilitatorSession) {
      history.replace(`/session/${config.session.startId}`);
    }
  }, [relaxationOnly, facilitatorSession, history]);

  // HACK (Safari MacOS/iOS):
  // Therapy Session audio fails to start unless we load SOME audio beforehand...
  // So we load dummy silence audio if they try to go directly to Therapy Session...
  // Seems to work
  function goToRoute(route: string): void {
    AudioManager.createAudio({
      src: config.audio.silence,
      onplay: () => {
        history.push(route);
      },
    });
  }

  if (relaxationOnly || !therapySession) {
    return (
      <>
        <main className="align-stretch flex flex-col items-stretch justify-center flex-1 p-8 text-center">
          <header className="pt-8 pb-2 text-center">
            <p className="mx-auto">Loading...</p>
          </header>
        </main>
      </>
    );
  }

  return (
    <>
      <main className="align-stretch flex flex-col items-stretch justify-center flex-1 p-8 text-center">
        <header className="pt-8 pb-2 text-center">
          <p className="mx-auto">Please select the type of session you wish to have at this time.</p>
        </header>
        <button onClick={() => goToRoute(routes.Meditation)} className="button my-4">
          Relaxation Only
        </button>
        <button onClick={() => goToRoute(routes.SessionGuide)} className="button button--primary my-4">
          Relaxation and Updating
        </button>
        <button
          onClick={() => goToRoute(`/session/${config.session.startId}`)}
          className="button button--secondary my-4"
        >
          Updating Only
        </button>

        <div className="border-gray py-4 mt-4 border-t">
          <div className="flex items-center uppercase justify-between">
            <p>Play inactivity alarms</p>
            <Switch
              checked={alarms?.hasAlarms}
              onChange={alarms?.setHasAlarms}
              className={`${
                alarms?.hasAlarms ? 'bg-blue' : 'bg-gray'
              } relative inline-flex h-10 w-20 items-center rounded-full`}
            >
              <span className="sr-only">Enable alarms</span>
              <span
                className={`${
                  alarms?.hasAlarms ? 'translate-x-11' : 'translate-x-1'
                } inline-block h-8 w-8 transform rounded-full bg-white transition-all`}
              />
            </Switch>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default React.memo(Home);
