/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import ReactGA from 'react-ga';
import PWAPrompt from 'react-ios-pwa-prompt';
import { Route, Switch } from 'react-router-dom';
import Home from './components/Home';
import Meditation from './components/Meditation';
import RelaxationIntro from './components/RelaxationIntro';
import RelaxationIntroNo from './components/RelaxationIntroNo';
import ReorientationQuestion from './components/ReorientationQuestion';
import SessionGuide from './components/SessionGuide';
import SessionTerminatedSeekAssistance from './components/SessionTerminatedSeekAssistance';
import TestAudioPlayback from './components/TestAudioPlayback';
import TherapySession from './components/TherapySession';
import TherapySessionIntro from './components/TherapySessionIntro';
import config from './config/config';
import routes from './config/routes';
import { TherapySessionProvider } from './contexts/TherapySessionContext';

const App: React.FC = () => {
  ReactGA.initialize(config.ga.id);

  React.useEffect(() => {
    let wakeLock: WakeLockSentinel | null;

    function release() {
      if (wakeLock) {
        wakeLock.release().then(() => {
          wakeLock = null;
        });
      }
    }

    async function lock() {
      if (document.visibilityState === 'visible' && 'wakeLock' in navigator) {
        wakeLock = await navigator.wakeLock.request('screen');
      }
    }

    lock();
    document.addEventListener('visibilitychange', lock);

    return function cleanup() {
      release();
      document.removeEventListener('visibilitychange', lock);
    };
  }, []);

  return (
    <>
      <TherapySessionProvider>
        <div className="top-1 right-1 absolute pointer-events-none" style={{ fontSize: '12px' }}>
          <div className="opacity-10 ">{config.version}</div>
        </div>
        <Switch>
          <Route path={routes.Home} exact component={Home} />
          <Route path={routes.Meditation} exact component={Meditation} />
          <Route path={routes.RelaxationIntro} exact component={RelaxationIntro} />
          <Route path={routes.RelaxationIntroNo} exact component={RelaxationIntroNo} />
          <Route path={routes.Reorientation} exact component={ReorientationQuestion} />
          <Route path={routes.Session} exact component={TherapySession} />
          <Route path={routes.SessionGuide} exact component={SessionGuide} />
          <Route path={routes.SessionIntro} exact component={TherapySessionIntro} />
          <Route path={routes.SessionTerminated} exact component={SessionTerminatedSeekAssistance} />
          <Route path={routes.TestAudioPlayback} exact component={TestAudioPlayback} />
        </Switch>
        {/* {Object.values(config.audio).map(audio => (
          <>
            <link rel="preload" as="audio" href={audio} />
            <audio key={audio} src={audio} />
          </>
        ))} */}
        <PWAPrompt copyBody="This website has app functionality. Add it to your home screen to use it in fullscreen." />
      </TherapySessionProvider>
    </>
  );
};

export default App;
