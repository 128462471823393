import React from 'react';
import PauseOverlay from '../components/PauseOverlay';
import config from '../config/config';
import KEYBINDS from '../config/keybinds';
import AudioManager from '../lib/AudioManager';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const usePauseMenu = ({
  showResume = true,
  goBack,
  endGradually,
}: { showResume?: boolean; goBack?: () => void; endGradually?: () => void } = {}) => {
  const { play, replay, pause } = AudioManager;
  const [showPauseMenu, setShowPauseMenu] = React.useState(false);

  React.useEffect(() => {
    function onKeyup(event: KeyboardEvent) {
      if (KEYBINDS.Pause.includes(event.code)) {
        if (showPauseMenu) {
          setShowPauseMenu(false);
          replay();
        } else {
          setShowPauseMenu(true);
        }
      }
    }

    window.addEventListener('keyup', onKeyup);

    return () => {
      window.removeEventListener('keyup', onKeyup);
    };
  }, [replay, showPauseMenu]);

  const PauseMenu = () => (
    <>
      <button
        type="button"
        className="session-pause-exit__button--hasPauseOption mt-auto"
        onClick={() => {
          setShowPauseMenu(true);
          pause();
        }}
      >
        <span className="h2 text-blue text-center">Pause</span>
      </button>
      {showPauseMenu && (
        <PauseOverlay
          resume={
            showResume
              ? () => {
                  setShowPauseMenu(false);
                  play();
                }
              : undefined
          }
          goBack={
            goBack
              ? () => {
                  setShowPauseMenu(false);
                  goBack();
                }
              : undefined
          }
          endGradually={
            endGradually
              ? () => {
                  setShowPauseMenu(false);
                  endGradually();
                }
              : undefined
          }
          replay={() => {
            setShowPauseMenu(false);
            replay();
          }}
        />
      )}
    </>
  );

  return { showPauseMenu, setShowPauseMenu, PauseMenu };
};

export default usePauseMenu;
