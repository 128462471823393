import React from 'react';
import ReactGA from 'react-ga';
import { RouteComponentProps } from 'react-router-dom';
import config from '../config/config';
import routes from '../config/routes';
import { useTherapySession } from '../contexts/TherapySessionContext';
import createTherapySessionRelaxation from '../data/therapySessions/createTherapySessionRelaxation';
import AudioManager from '../lib/AudioManager';
import { RelaxationType } from '../models/RelaxationType.enum';
import IconLongTimer from './icons/IconLongTimer';
import IconMediumTimer from './icons/IconMediumTimer';
import IconShortTimer from './icons/IconShortTimer';

const Meditation: React.FC<RouteComponentProps> = ({ history }) => {
  const [relaxationSrc, setRelaxationSrc] = React.useState<string>();
  const [isPaused, setIsPaused] = React.useState<boolean>(true);
  const [isLoading, setIsLoading] = React.useState(true);

  const { therapySession } = useTherapySession();

  React.useEffect(() => ReactGA.pageview(routes.Meditation), []);

  function getRelaxationSrc(type: RelaxationType): string {
    switch (type) {
      case RelaxationType.Short: {
        return config.audio.relaxationShort;
      }
      case RelaxationType.Medium: {
        return config.audio.relaxationMedium;
      }
      case RelaxationType.Long: {
        return config.audio.relaxationLong;
      }
    }
  }

  const playRelaxation = React.useCallback(
    (type: RelaxationType) => {
      const src = getRelaxationSrc(type);

      setIsPaused(false);
      setRelaxationSrc(src);
      setIsLoading(true);

      createTherapySessionRelaxation(therapySession.id, type);

      window.setTimeout(() => {
        AudioManager.createAudio({
          src,
          onload: () => {
            setIsLoading(false);
          },
          onloaderror: () => {
            setIsLoading(false);
          },
          onend: () => {
            history.push(routes.Reorientation);
          },
        });
      }, config.relaxation.delay);
    },
    [history, therapySession?.id],
  );

  return (
    <>
      <header className="pt-8 pb-2 text-center">
        <h1 className="h1 mx-auto mb-2">Relaxation Sessions</h1>
      </header>
      {!relaxationSrc ? (
        <>
          <main className="justify-stretch align-stretch flex flex-col flex-1 p-4 text-center">
            <div className="sm:max-w-md mx-auto">
              <p className="mb-2">You are invited to listen to a recording to help ease you into a meditative state.</p>
              <p>We have short, medium, and long recordings available.</p>
            </div>
            <h2 className="h3 md:mx-auto my-4">Please choose duration</h2>
            <div className="md:flex-row flex flex-col justify-start flex-auto text-center">
              <button
                type="button"
                className="md:inline-block md:mx-2 button button--primary md:w-auto md:h-36 block w-56 mx-auto mb-3 leading-none"
                onClick={() => playRelaxation(RelaxationType.Short)}
              >
                <div className="block w-1/2 mx-auto mb-2 opacity-50">
                  <IconShortTimer />
                </div>
                Short
                <br />
                <small className="inline-block mt-2 text-xs leading-tight">About 2 minutes</small>
              </button>{' '}
              <button
                type="button"
                className="md:inline-block md:mx-2 button button--primary md:w-auto md:h-36 block w-56 mx-auto mb-3 leading-none"
                onClick={() => playRelaxation(RelaxationType.Medium)}
              >
                <div className="block w-1/2 mx-auto mb-2 opacity-50">
                  <IconMediumTimer />
                </div>
                Medium
                <br />
                <small className="inline-block mt-2 text-xs leading-tight">About 7 minutes</small>
              </button>{' '}
              <button
                type="button"
                className="md:inline-block md:mx-2 button button--primary md:w-auto md:h-36 block w-56 mx-auto mb-3 leading-none"
                onClick={() => playRelaxation(RelaxationType.Long)}
              >
                <div className="block w-1/2 mx-auto mb-2 opacity-50">
                  <IconLongTimer />
                </div>
                Long
                <br />
                <small className="inline-block mt-2 text-xs leading-tight">About 14 minutes</small>
              </button>
            </div>
          </main>
        </>
      ) : (
        <main className="align-stretch flex flex-col justify-center flex-1 p-4 text-center">
          {isLoading ? (
            <h2 className="h3 md:mx-auto my-4">Loading audio...</h2>
          ) : (
            <>
              {isPaused === false ? (
                <button
                  type="button"
                  className="button button--primary my-4"
                  onClick={() => {
                    setIsPaused(true);
                    AudioManager.pause();
                  }}
                >
                  Pause
                </button>
              ) : (
                <button
                  type="button"
                  className="button button--primary my-4"
                  onClick={() => {
                    setIsPaused(false);
                    AudioManager.play();
                  }}
                >
                  Play
                </button>
              )}
              <button
                type="button"
                className="button button--secondary my-4"
                onClick={() => {
                  AudioManager.pause();
                  setRelaxationSrc(undefined);
                }}
              >
                Select a different relaxation
              </button>
            </>
          )}
        </main>
      )}
      <a href={config.api.root} className="session-pause-exit__button">
        <span className="h2 text-blue text-center">Exit</span>
      </a>
    </>
  );
};

export default Meditation;
